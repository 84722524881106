export interface Member {
  id: number;
  name: string;
  firstName: string;
  dwz?: string;
  elo?: string;
  count?: string;
  extern?: boolean;
}

export const members: Member[] = [
  {
    id: 1,
    name: 'Rahn',
    firstName: 'Jörg'
  },
  {
    id: 2,
    name: 'Felber',
    firstName: 'Frank'
  },
  {
    id: 3,
    name: 'Rosenthal',
    firstName: 'Jörg'
  },
  {
    id: 4,
    name: 'Aßmus',
    firstName: 'Günther'
  },
  {
    id: 5,
    name: 'Wege',
    firstName: 'Hermann'
  },
  {
    id: 6,
    name: 'Dummer',
    firstName: 'Arne'
  },
  {
    id: 7,
    name: 'Gernitz',
    firstName: 'Albrecht'
  },
  {
    id: 8,
    name: 'Jasch',
    firstName: 'André'
  },
  {
    id: 9,
    name: 'Gottschalk',
    firstName: 'Nico'
  },
  {
    id: 10,
    name: 'Gottschalk',
    firstName: 'Juliane'
  },
  {
    id: 11,
    name: 'Main',
    firstName: 'Thomas'
  },
  {
    id: 12,
    name: 'Schröder',
    firstName: 'Wolfgang'
  },
  {
    id: 13,
    name: 'Bresselschmitt',
    firstName: 'Bernd'
  },
  {
    id: 100,
    name: '',
    firstName: ''
  }
];
