import {NgModule} from '@angular/core';
import {NewsComponent} from './news.component';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    NewsComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        RouterModule
    ],
  exports: [
    NewsComponent
  ]
})
export class NewsModule { }
