import {NgModule} from '@angular/core';
import {NewsPageComponent} from './news-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [
    NewsPageComponent
  ],
    imports: [
        MatListModule,
        CommonModule,
        NewsModule,
        MatCardModule
    ],
  exports: [
    NewsPageComponent
  ]
})
export class NewsPageModule { }
