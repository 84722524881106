import {NgModule} from '@angular/core';
import {CalendarComponent} from './calendar.component';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    CalendarComponent
  ],
  imports: [
    CommonModule,
    MatTableModule
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule { }
