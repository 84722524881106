import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ResourcesService} from '../../service/resources';

@Component({
  selector: 'html-table',
  templateUrl: './html-table.component.html',
  styleUrls: ['./html-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HtmlTableComponent implements OnInit {
  constructor(
    public resourcesService: ResourcesService,
  ) {
  }
  @HostBinding('class') class = 'htmltable';
  @Input() resource: string;
  @Input() title: string;
  html: string;

  ngOnInit(): void {
    const url = `/assets/data/${this.resource}`;
    this.resourcesService.getResourceAsHTMLTable(url).subscribe(
      file => this.html = file
    );
  }
}
