import {NgModule} from '@angular/core';
import {FeatureTableComponent} from './feature-table.component';
import {HtmlTableModule} from '../html-table/html-table.module';
import {CommonModule} from '@angular/common';
import {ResultTableModule} from '../result-table/result-table.module';
import {QuicktableModule} from '../quicktable/quicktable.module';

@NgModule({
  declarations: [
    FeatureTableComponent
  ],
  imports: [
    CommonModule,
    HtmlTableModule,
    ResultTableModule,
    QuicktableModule
  ],
  exports: [
    FeatureTableComponent
  ]
})
export class FeatureTableModule { }
