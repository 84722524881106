import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {BP, ResponsiveService} from '../../service/responsive';
import {YearTounaments} from '../../data/tournaments';

@Component({
  selector: 'year-tournaments',
  templateUrl: './year-tournaments.component.html',
  styleUrls: ['./year-tournaments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class YearTournamentsComponent {
  constructor(
    public responsiveService: ResponsiveService,
  ) {
  }

  @HostBinding('class') class = 'year-tournaments';
  @Input() tournaments: YearTounaments;

  isGtMd$ = this.responsiveService.isBreakpoint$(BP.GtMd);
  types: string[] = ['stadt', 'verein', 'blitz'];

}
