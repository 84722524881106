import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CalendarItem} from '../../data/calendar';
import {BP, ResponsiveService} from '../../service/responsive';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
  constructor(
    public responsiveService: ResponsiveService,
  ) {
  }
  isNotMobile$ = this.responsiveService.isBreakpoint$(BP.GtXs);

  @HostBinding('class') class = 'calendar';
  @Input() calendar: CalendarItem[];

  displayedColumns$: Observable<string[]> = this.isNotMobile$.pipe(
    map (isnotmobile => isnotmobile ? ['datetime', 'headline', 'location', 'link'] : ['datetime', 'headline']),
  );
  dataSource: CalendarItem[] = [];

  ngOnInit(): void {
    this.dataSource = this.calendar;
  }
}
