<mat-card *ngIf="page$ | async as page">
  <mat-card-title>{{page.headline}}</mat-card-title>
  <mat-card-content>
    <p *ngIf="page.abstract">
    {{page.abstract}}
    </p>
    <ng-container *ngFor="let section of page.sections">
      <div class="section">
        <h3 *ngIf="section.headline">{{section.headline}}</h3>
        <p [innerHTML]="section.paragraph" *ngIf="section.paragraph"></p>
        <html-table [resource]="section.htmlTableResource" *ngIf="section.htmlTableResource"></html-table>
        <html-paragraph [resource]="section.paragraphResource" *ngIf="section.paragraphResource"></html-paragraph>
        <quicktable *ngIf="section.quicktable" [data]="section.quicktable"></quicktable>
        <feature-table *ngIf="section.featureTableResource"></feature-table>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
