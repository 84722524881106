import {Component} from '@angular/core';
import {ResourcesService} from '../../service/resources';
import {CalendarItem} from '../../data/calendar';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DateService} from '../../service/date/date.service';

@Component({
  selector: 'news-page',
  templateUrl: './calendar-page.component.html',
  // styleUrls: ['./news-page.component.scss']
})

export class CalendarPageComponent {
  constructor(
    private resourcesService: ResourcesService,
    private dateService: DateService,
  ) {
  }
  currentCalendar$: Observable<CalendarItem[]> = this.resourcesService.getResourceAsJSON('calendar').pipe(
    map(calendar => (calendar  as CalendarItem[]).filter(item => !item.archive)),
    map(calendar => this.dateService.sortItemsByDate(calendar) as CalendarItem[])
  );

}

