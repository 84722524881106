import {NgModule} from '@angular/core';
import {HtmlTableComponent} from './html-table.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {ResourcesModule} from '../../service/resources';
import {QuicktableModule} from '../quicktable/quicktable.module';

@NgModule({
  declarations: [
    HtmlTableComponent
  ],
    imports: [
        MatListModule,
        CommonModule,
        NewsModule,
        MatCardModule,
        ResourcesModule.forRoot(),
        QuicktableModule
    ],
  exports: [
    HtmlTableComponent
  ]
})
export class HtmlTableModule { }
