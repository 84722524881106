import {Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {BP, ResponsiveService} from '../../service/responsive';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'quicktable',
  templateUrl: './quicktable.component.html',
  styleUrls: ['./quicktable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuicktableComponent implements OnChanges {
  constructor(
    public responsiveService: ResponsiveService,
  ) {
  }
  isNotMobile$ = this.responsiveService.isBreakpoint$(BP.GtXs);

  displayedColumns: string[] = [];
  headlines: {[key: string]: string} = {};

  @HostBinding('class') class = 'quicktable';
  @Input() data: string;
  @Input() title: string;

  displayedColumns$: Observable<string[]> = this.isNotMobile$.pipe(
    map (isnotmobile => isnotmobile ? ['datetime', 'headline', 'location', 'link'] : ['datetime', 'headline']),
  );
  dataSource: {[key: string]: string}[] = [];

  private createTableData(): void {
    this.displayedColumns = [];
    const lines = this.data.split('#');
    const head = lines[0].split('|');
    let i = 0;
    head.forEach(
      h => {
        const key = 'col' + i++;
        this.headlines[key] = h;
        this.displayedColumns.push(key);
      }
    );
    const tab = lines.slice(1).map(line => line.split('|'));
    tab.forEach(
      line => {
        let i2 = 0;
        const lineobj = {};
        this.displayedColumns.forEach(
          col => {
            lineobj[col] = line[i2];
            i2++;
          }
        );
        this.dataSource.push(lineobj);
      }
    );
  }

  ngOnChanges(): void {
    this.createTableData();
  }
}
