import {NgModule} from '@angular/core';
import {ShortNewsComponent} from './short-news.component';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {NewsModule} from '../news/news.module';

@NgModule({
  declarations: [
    ShortNewsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule,
    MatListModule,
    NewsModule
  ],
  exports: [
    ShortNewsComponent
  ]
})
export class ShortNewsModule { }
