import {Injectable} from '@angular/core';

interface DateItem {
  date?: string;
}

@Injectable()
export class DateService {

  static compareDates(dateA: string , dateB: string): number {
    return this.getNumber(dateA) - this.getNumber(dateB);
  }

  static getNumber(date: string): number {
    const splitdate = date?.split('.');
    const year = splitdate.length < 3 ? +splitdate[0] : +splitdate[2];
    const month = splitdate.length === 1 ? 0 : +splitdate[1];
    const day = splitdate.length === 1 ? 0 : +splitdate[0];
    const num = year * 100000 + (month - 1) * 12 * 100 + day - 1;
    return num;
  }

  sortItemsByDate(items: DateItem[]): DateItem[] {
    return items.sort((dateA, dateB) => DateService.compareDates(dateA.date, dateB.date));
  }

}
