<mat-card *ngIf="news$ | async as news">
  <mat-card-title>{{news.headline}}</mat-card-title>
  <mat-card-subtitle>{{news.date}}</mat-card-subtitle>
  <mat-card-content>
    <p class="abstract">
    {{news.abstract}}
    </p>
    <ng-container  *ngIf="message$ | async as message">
      <p [innerHTML]="message">
      </p>
    </ng-container>
    <ng-container *ngIf="channel$ | async as channel">
      <a routerLink="/{{channel.id}}">{{channel.backText}}</a>
    </ng-container>
  </mat-card-content>
</mat-card>
