import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Channel, News} from '../../data/news';

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsComponent implements OnInit {
  @HostBinding('class') class = 'news';
  @Input() news: News;
  @Input() channel: Channel;

  linkname: string;

  ngOnInit(): void {
    this.linkname = this.news?.linkname ? this.news.linkname : 'Weitere Infos';
  }
}
