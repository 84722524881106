import {NgModule} from '@angular/core';
import {QuicktableComponent} from './quicktable.component';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    QuicktableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule
  ],
  exports: [
    QuicktableComponent
  ]
})
export class QuicktableModule { }
