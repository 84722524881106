import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ResourcesService} from '../../service/resources';

@Component({
  selector: 'html-paragraph',
  templateUrl: './html-paragraph.component.html',
  styleUrls: ['./html-paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HtmlParagraphComponent implements OnInit {
  constructor(
    public resourcesService: ResourcesService,
  ) {
  }
  @HostBinding('class') class = 'htmlparagraph';
  @Input() resource: string;
  html: string;

  ngOnInit(): void {
    const url = `/assets/data/html/${this.resource}.html`;
    this.resourcesService.getResource(url).subscribe(
      file => this.html = file
    );
  }
}
