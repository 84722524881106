import {NgModule} from '@angular/core';
import {TournamentsPageComponent} from './tournaments-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {TournamentTableModule} from '../../components/tournament-table/tournament-table.module';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {YearTournamentsModule} from '../../components/year-tournaments/year-tournaments.module';

@NgModule({
  declarations: [
    TournamentsPageComponent
  ],
  imports: [
    MatListModule,
    CommonModule,
    NewsModule,
    MatCardModule,
    TournamentTableModule,
    MatIconModule,
    RouterModule,
    YearTournamentsModule
  ],
  exports: [
    TournamentsPageComponent
  ]
})
export class TournamentsPageModule { }
