import {NgModule} from '@angular/core';
import {YearTournamentsComponent} from './year-tournaments.component';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    YearTournamentsComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    RouterModule,
    MatIconModule
  ],
  exports: [
    YearTournamentsComponent
  ]
})
export class YearTournamentsModule { }
