export enum DynamicTableType {
  Pairings = 'pairings',
  CrossTable = 'crosstable',
  Standings = 'standings',
}
export interface GroupedTounaments {
  [key: string]: Tournament;
}

export interface YearTounaments {
  [key: string]: GroupedTounaments;
}

export interface TournamentTable {
  isDynamic: boolean;
  headline: string;
  resource?: string;
  timecontrols: string[];
  dynamicTableType?: DynamicTableType;
}

export interface TournamentData {
  members?: number[];
  results?: string[][];
  dates?: string[];
  round?: string;
  roundMap: number[];
}

export interface TournamentType {
  id: string;
  name: string;
}

export interface Tournament {
  id: string;
  typeId: string;
  yearDisplay: string;
  yearResource: string;
  tables: TournamentTable[];
  currentRound?: number;
  finished: boolean;
  roundTable?: TournamentTable;
  subTitle?: string;
  announcement?: string;
  tournamentData?: TournamentData;
  roundTournamentData?: TournamentData[];
}

export interface Round {
  num: number;
  headline: string;
  link: string;
}

export const tournamentTypes: TournamentType[] = [
  {
    id: 'blitz',
    name: 'Blitz-Cup'
  },
  {
    id: 'stadt',
    name: 'Stadtmeisterschaft'
  },
  {
    id: 'verein',
    name: 'Vereinsmeisterschaft'
  },
];

export const PAIRINGS_8 = [
  [[0, 7], [1, 6], [2, 5], [3, 4]],
  [[7, 4], [5, 3], [6, 2], [0, 1]],
  [[1, 7], [2, 0], [3, 6], [4, 5]],
  [[7, 5], [6, 4], [0, 3], [1, 2]],
  [[2, 7], [3, 1], [4, 0], [5, 6]],
  [[7, 6], [0, 5], [4, 1], [2, 3]],
  [[3, 7], [4, 2], [5, 1], [6, 0]],
];

export const PAIRINGS_6 = [
  [[0, 5], [1, 4], [2, 3]],
  [[5, 3], [4, 2], [0, 1]],
  [[1, 5], [2, 0], [3, 4]],
  [[5, 4], [0, 3], [1, 2]],
  [[2, 5], [3, 1], [4, 0]]
];

