import {Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {News} from '../../data/news';
import {ActivatedRoute} from '@angular/router';
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ResourcesService} from '../../service/resources';

@Component({
  selector: 'news-page',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsDetailComponent {
  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService
  ) {}
  @HostBinding('class') class = 'news-detail';

  channel$ = this.route.data.pipe(
    map(data => data.channel),
  );

  news$: Observable<News> = this.channel$.pipe(
    switchMap( channel => this.resourcesService.getResourceAsJSON(channel.id)),
    withLatestFrom(this.route.params),
    filter( ([news, params]) => !!params.id),
    map( ([news, params]) => (news as News[]).find(n => n.id === params.id)),
    filter( n => !!n)
  );

  message$: Observable<string> = this.news$.pipe(
    map(news => this.wrapParagraphs(news.message))
  );

  private wrapParagraphs(message: string): string {
    return message.replace(/\n/g, '</p><p>');
  }



}
