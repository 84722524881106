import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {take, withLatestFrom} from 'rxjs/operators';
import {Round, Tournament, TournamentData, TournamentTable, tournamentTypes} from '../../data/tournaments';
import {Observable} from 'rxjs';
import {ResourcesService} from '../../service/resources';

@Component({
  selector: 'news-page',
  templateUrl: './tournament-page.component.html',
  styleUrls: ['./tournament-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TournamentPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService
  ) {
  }
  @HostBinding('class') class = 'tournament-page';

  tournament: Tournament;
  title: string;
  tables: TournamentTable[] = [];
  rounds: Round [] = [];
  round: string;
  backlink: string;
  tournaments$: Observable<Tournament[]> = this.resourcesService.getResourceAsJSON('tournaments') as Observable<Tournament[]>;
  tournamentData: TournamentData;

  ngOnInit(): void {
    this.tournaments$.pipe(
      withLatestFrom(this.route.params),
      take(1)
    ).subscribe(
      ([tournaments, params]) => {
        this.tournament = tournaments.find( tn => tn.yearResource === params.year && tn.typeId === params.type);
        const tournamentType = tournamentTypes.find(tt => tt.id === this.tournament.typeId);
        this.title = tournamentType.name + ' ' + this.tournament.yearDisplay;
        if (!params.round) {
          this.tables = this.tournament.tables.map(
            tab => ({...tab, headline: `${tab.headline} ${this.tournament.currentRound ? this.tournament.currentRound : ''}`})
          );
          if (!!this.tournament.roundTable) {
            for (let i = 0; i < this.tournament.currentRound; i++) {
              this.rounds.push({
                num: i + 1,
                headline: this.tournament.roundTable.headline
                  .replace('xx', (i + 1).toString())
                  .replace('yy', this.tournament.roundTable.timecontrols[i]),
                link: `/tournament/${this.tournament.yearResource}/${this.tournament.typeId}/${(i + 1).toString()}`
              });
            }
          }
          this.tournamentData = this.tournament.tournamentData;
        } else {
          this.round = params.round;
          this.tables = [
            {
              ...this.tournament.roundTable,
              headline: this.tournament.roundTable.headline.replace('xx', this.round)
                .replace('yy', this.tournament.roundTable.timecontrols[+this.round - 1])
            }
          ];
          this.backlink = `/tournament/${this.tournament.yearResource}/${this.tournament.typeId}`;
          this.tournamentData = this.tournament.roundTournamentData?.find(rtd => rtd.round === this.round);

        }
      }
    );
  }

}
