import {NgModule} from '@angular/core';
import {TournamentTableComponent} from './tournament-table.component';
import {HtmlTableModule} from '../html-table/html-table.module';
import {CommonModule} from '@angular/common';
import {ResultTableModule} from '../result-table/result-table.module';

@NgModule({
  declarations: [
    TournamentTableComponent
  ],
    imports: [
        CommonModule,
        HtmlTableModule,
        ResultTableModule
    ],
  exports: [
    TournamentTableComponent
  ]
})
export class TournamentTableModule { }
