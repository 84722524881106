<mat-card *ngIf="currentNews$ | async as currentNews">
  <mat-card-title>{{channel.shortTitle}}</mat-card-title>
  <mat-card-content>
    <mat-list>
      <div mat-list-item class="newsitem" *ngFor="let newsItem of currentNews">
        <news [news]="newsItem" [channel]="channel"></news>
      </div>
    </mat-list>
    <a routerLink="/{{channel.id}}">{{channel.backText}}</a>
  </mat-card-content>
</mat-card>
