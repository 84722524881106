import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class ResourcesService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }
    getResource(url): Observable<any> {
      return this.httpClient.get(url, {responseType: 'text'});
    }

  getResourceAsJSON(jsonResource): Observable<object> {
    return this.httpClient.get(`/assets/data/json/${jsonResource}.json` , {responseType: 'json'});
  }

  getResourceAsHTMLTable(url): Observable<any> {
      return this.getResource(url).pipe(
        map( html => this.parseTable(html))
      );
    }

    private parseTable(origHtml: string): string {
      const ret = [];
      const html = this.stripTags(['span', 'p', 'b'], origHtml);
      const tabPosStart = html.indexOf('<table');
      const tabPosEnd = html.indexOf('</table');
      const newHtml = html.substr(tabPosStart, tabPosEnd - tabPosStart + 8);
      let lasttrpos = 0;
      let trpos = 0;
      while ( trpos > -1) {
        trpos = newHtml.indexOf('<tr', lasttrpos);
        const trposEnd = newHtml.indexOf('</tr', trpos);
        const trHTML = newHtml.substr(trpos, trposEnd - trpos + 5);
        lasttrpos = trposEnd + 4;
        if (trpos === -1) {
          break;
        } else {
          ret.push(this.parseTr(trHTML).join('|'));
        }
      }
      return ret.join('#');
    }

    private parseTr(html: string): string[] {
      const ret = [];
      let lasttdpos = 0;
      let tdpos = 0;
      while ( tdpos > -1) {
        tdpos = html.indexOf('<td', lasttdpos);
        const tdposEnd = html.indexOf('</td', tdpos);
        const tdHTML = html.substr(tdpos, tdposEnd - tdpos + 5);
        lasttdpos = tdposEnd + 4;
        if (tdpos === -1) {
          break;
        } else {
          // console.log('Extract text: ', tdHTML);
          ret.push(this.extractText(tdHTML));
        }
      }
      return ret;
    }

    private extractText(html: string): string {
      const tdtagpos = html.indexOf('<td');
      const tdtagclose = html.indexOf('>', tdtagpos);
      const tdtagclosepos = html.indexOf('</td');
      const ret = html.substr(tdtagclose + 1, tdtagclosepos - tdtagclose -1);
      return ret;
    }


  private stripTags(tagnames: string[], html: string): string {
    let ret = html;
    tagnames.forEach( tag => {
      ret = this.stripTag(tag, ret);
      ret = this.stripTag('/' + tag, ret);
    });
    return ret;
  }

  private stripTag(tagname: string, html: string): string {
    let stripped = '';
    let lasttagpos = 0;
    let tagpos = 0;
    while ( tagpos > -1) {
      tagpos = html.indexOf('<' + tagname, lasttagpos);
      if (tagpos > -1) {
        stripped = stripped + html.substr(lasttagpos, tagpos - lasttagpos);
        const tagend = html.indexOf('>', tagpos);
        if (tagend > -1) {
          lasttagpos = tagend + 1;
        } else {
          break;
        }
      }
    }
    return stripped + html.substr(lasttagpos, html.length - lasttagpos);
  }

}
