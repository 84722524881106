<ng-container *ngIf="tournaments">
  <ng-container *ngFor="let type of types">
    <div *ngIf="tournaments[type] || (isGtMd$ | async)">
      <ng-container *ngIf="tournaments[type]">
      <a [routerLink]="tournaments[type].link">
        <span>{{tournaments[type].name}} {{tournaments[type].yearDisplay}}</span><mat-icon mat-list-icon>arrow_right</mat-icon>
      </a>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
