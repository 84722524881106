import {ModuleWithProviders, NgModule} from '@angular/core';
import {ResponsiveService} from './responsive.service';

@NgModule({
  imports: [
  ]
})
export class ResponsiveModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ResponsiveModule,
      providers: [
        ResponsiveService,
      ],
    };
  }
}
