<ng-container *ngIf="dataSource">
  <div class="paragraph" *ngIf="title">{{title}}</div>
  <table mat-table [dataSource]="dataSource">

    <ng-container *ngFor="let col of displayedColumns">
      <ng-container matColumnDef="{{col}}">
        <th mat-header-cell *matHeaderCellDef><span [innerHTML]=headlines[col]></span></th>
        <td mat-cell *matCellDef="let element"><span [innerHTML]=element[col]></span></td>
      </ng-container>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>
