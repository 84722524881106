<ng-container *ngIf="{isGtsm: isGtSm$ | async} as status">
  <mat-toolbar>
    <header>
       <div *ngIf="!status.isGtsm">
          <mat-icon (click)="sidenav.toggle();">menu</mat-icon>
       </div>
      <div>
        <img src="/assets/knight.svg">
      </div>
       <div class="head">
          <span class="title">{{title}}</span>
       </div>
    </header>
  </mat-toolbar>
  <mat-sidenav-container>

    <mat-sidenav-content>
      <main>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
        <div class="rightcol" *ngIf="data$ | async as data">
          <quicktable [data]="data" [title]="title$ | async"></quicktable>
        </div>
      </main>
      <footer>

      </footer>
    </mat-sidenav-content>

    <mat-sidenav #sidenav [opened]="status.isGtsm " [mode]="status.isGtsm ? 'side' : 'over'">
      <div class="navtitle">SCR</div>
      <mat-nav-list>
        <a mat-list-item (click)="openMenuItem('/', !status.isGtsm)">Home </a>
        <a mat-list-item (click)="openMenuItem('/news', !status.isGtsm)">Nachrichten</a>
        <a mat-list-item (click)="openMenuItem('/calendar', !status.isGtsm)">Termine</a>
        <a mat-list-item (click)="openMenuItem('/content/members', !status.isGtsm)">Mitglieder</a>
        <a mat-list-item (click)="openMenuItem('/content/training', !status.isGtsm)">Training</a>
        <a mat-list-item (click)="openMenuItem('/content/charge', !status.isGtsm)">Beitrag</a>
        <a mat-list-item (click)="openMenuItem('/content/satzung', !status.isGtsm)">Satzung</a>
        <a mat-list-item (click)="openMenuItem('/content/impressum', !status.isGtsm)">Impressum</a>
      </mat-nav-list>
      <div class="navtitle">Turniere</div>
      <mat-nav-list>
        <a mat-list-item (click)="openMenuItem('/tournaments', !status.isGtsm)">Übersicht </a>
      </mat-nav-list>
      <div class="navtitle">Welt des Schachs</div>
      <mat-nav-list>
        <a mat-list-item (click)="openMenuItem('/chessworld', !status.isGtsm)">News </a>
      </mat-nav-list>
    </mat-sidenav>

  </mat-sidenav-container>
</ng-container>
