import {Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {ResourcesService} from '../../service/resources';
import {filter, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Member} from '../../data/members';

@Component({
  selector: 'feature-table',
  templateUrl: './feature-table.component.html',
  styleUrls: ['./feature-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FeatureTableComponent  {
  @HostBinding('class') class = 'featuretable';
  constructor(
    private resourcesService: ResourcesService
  ) {
  }

  members$ = this.resourcesService.getResourceAsJSON('members').pipe(
    filter( ns => !!ns),
    map( ns => (ns as Member[]).filter(n => !n.extern)),
    map( ns => ns.sort((a, b)  => this.compareMembers(a, b))),
    map(ns => 'Pl.|Name|DWZ|Elo#' + (ns as Member[]).map((n, i) => this.getLine(i, n)).join('#')),
  );

  getLine(index: number, member: Member): string {
    const count = member.count ? ` - ${member.count}` : '';
    const dwz = member.dwz ? `${member.dwz}${count}` : '-';
    const elo = member.elo ? member.elo : '-';
    return (index + 1 ) + '|'
      + member.name + ', ' + member.firstName + '|'
      + dwz + '|'
      + elo;
  }

  compareMembers(a: Member, b: Member): number {
    return this.getMemberValue(b) - this.getMemberValue(a);
  }

  getMemberValue(member: Member): number {
    if (member.dwz) {
      if (member.dwz === 'Restp.') {
        return 0;
      } else {
        return +member.dwz;
      }
    } else {
      return -1;
    }
  }

}
