  <h1>Schachclub Rathenow</h1>
  <p>der SCR, seit 1992. Schachtradition in Rathenow, seit mehr als 100 Jahren.</p>
  <h2>Schach neu erleben: Komm zu uns und setze Matt echten Brett!</h2>
  <p>Entdecke die Magie des Schachspiels abseits des Bildschirms! Bist du leidenschaftlicher Online-Schachspieler und suchst nach einer neuen Herausforderung? Schließ dich uns im Schachclub Rathenow an und erlebe die faszinierende Welt des Schachs am realen Brett. <a href="/content/training">Unser Training</a> steht Spielern aller Levels offen. Komm unverbindlich vorbei, lerne Gleichgesinnte kennen und spüre die Spannung eines Schachspiels mit echten Gegnern. Wir laden dich herzlich ein, Teil unserer Gemeinschaft zu werden und die Freude des Schachspielens in der Region Rathenow neu zu entdecken – eine spannende Ergänzung zu deinen Online-Schachpartien!</p>
  <short-news [channel]="internalChannel"></short-news>

  <mat-card *ngIf="nextCalendarItem$ | async as nextCalendarItem">
    <mat-card-title>Nächster Termin</mat-card-title>
    <mat-card-content>
      <div class="lightcontent">
        <h3>{{nextCalendarItem.headline}}</h3>
        <p>
          {{nextCalendarItem.date}} {{nextCalendarItem.time}}, {{nextCalendarItem.location}}
        </p>
      </div>
      <a routerLink="/calendar">Alle Termine</a>
    </mat-card-content>
  </mat-card>

  <short-news [channel]="worldChessChannel"></short-news>
