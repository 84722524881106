import {Component, HostBinding, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {BP, ResponsiveService} from './service/responsive/responsive.service';
import {LigaTable, Team} from './data/tables';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ResourcesService} from './service/resources';
import {map, tap} from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Schachclub Rathenow';
  constructor(
    public responsiveService: ResponsiveService,
    private route: Router,
    private resourcesService: ResourcesService
  ) {
  }
  @HostBinding('class') class = 'chs-root';
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  isGtSm$ = this.responsiveService.isBreakpoint$(BP.GtSm);
  liga$: Observable<LigaTable[]> = this.resourcesService.getResourceAsJSON('liga') as Observable<LigaTable[]>;
  data$: Observable<string> = this.liga$.pipe(
    map( liga => this.makeLigaTable(liga[0])),
  );
  title$: Observable<string> = this.liga$.pipe(
    map( liga => liga[0].title),
  );

  openMenuItem(url: string, shouldToggle: boolean): void {
    if (shouldToggle) {
      this.sidenav.toggle();
    }
    this.route.navigateByUrl(url);
  }

  private getScore(team: Team): number {
    const wertung = team.wertung ?? 0;
    return +team.points.split('-')[0] * 10000 + team.boardPoints * 100 + wertung;
  }

  private makeLigaTable(liga: LigaTable): string {
    const data = liga.data.sort((a, b) => this.getScore(b) - this.getScore(a));
    return 'Pl.|Mannschaft|Punkte|Br#' + data.map( (l, i) => `${i + 1}|${l.name}|${l.points}|${l.boardPoints}`).join('#');
  }

}
