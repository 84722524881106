import {NgModule} from '@angular/core';
import {CalendarPageComponent} from './calendar-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {CalendarModule} from '../../components/calendar/calendar.module';
import {DateServiceModule} from '../../service/date/date-service.module';

@NgModule({
  declarations: [
    CalendarPageComponent
  ],
  imports: [
    MatListModule,
    CommonModule,
    NewsModule,
    MatCardModule,
    CalendarModule,
    DateServiceModule.forRoot()
  ],
  exports: [
    CalendarPageComponent
  ]
})
export class CalendarPageModule { }
