<ng-container *ngIf="calendar">
  <ng-container *ngIf="{
    isNotMobile: isNotMobile$ | async,
    displayedColumns: displayedColumns$ | async
    } as data"
  >
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="datetime">
        <th mat-header-cell *matHeaderCellDef> Datum / Zeit </th>
        <td mat-cell *matCellDef="let element"> {{element.date}} {{element.time}}</td>
      </ng-container>

      <ng-container matColumnDef="headline">
        <th mat-header-cell *matHeaderCellDef> Ereignis </th>
        <td mat-cell *matCellDef="let element"> {{element.headline}}
          <ng-container *ngIf="!data.isNotMobile">
            <br>{{element.location}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="data.isNotMobile">

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef> Ort </th>
          <td mat-cell *matCellDef="let element"> {{element.location}} </td>
        </ng-container>

        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"><a [href]="element.link" *ngIf="element.link">Infos</a></td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: data.displayedColumns;"></tr>
    </table>


  </ng-container>
</ng-container>
