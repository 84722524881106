<ng-container *ngIf="currentNews$ | async as currentNews">
  <mat-card>
    <mat-card-title>{{title$ | async}}</mat-card-title>
    <mat-card-content>
      <mat-list>
        <div mat-list-item class="newsitem" *ngFor="let newsItem of currentNews">
          <news [news]="newsItem" [channel]="channel$ | async"></news>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ng-container>


