export interface Channel {
  id: string;
  title: string;
  shortTitle: string;
  backText: string;
}

export interface News {
  id: string;
  date: string;
  headline: string;
  showhome: boolean;
  archive: boolean;
  abstract: string;
  message?: string;
  link?: string;
  externalLink?: string;
  linkname?: string;
}

export const newsChannel = {
  id: 'news',
  title: 'Nachrichten',
  shortTitle: 'Neueste Nachrichten',
  backText: 'Alle Nachrichten'
};

export const chessWorldChannel = {
  id: 'chessworld',
  title: 'Nachrichten',
  shortTitle: 'Aktuelles aus der Welt des Schachs',
  backText: 'Alle Nachrichten aus der Welt des Schachs'
};
