import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {DateService} from './date.service';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class DateServiceModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: DateServiceModule,
      providers: [
        DateService,
      ],
    };
  }
}
