<ng-container *ngIf="tournament">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle *ngIf="tournament.subTitle">{{tournament.subTitle}}</mat-card-subtitle>
        <a *ngIf="tournament.announcement" [routerLink]="tournament.announcement">Aussschreibung</a>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngFor="let table of tables">
          <tournament-table [year]="tournament.yearResource"
                            [tournamentType]="tournament.typeId"
                            [tableType]="table.resource"
                            [title]="table.headline"
                            [tournamentTable]="table"
                            [tournamentData]="tournamentData"
                            [round]="round"
          >

          </tournament-table>
        </ng-container>
        <ng-container *ngIf="rounds?.length > 0">
          <mat-list>
            <h4>Gespielte Runden</h4>
            <div mat-list-item *ngFor="let round of rounds">
              <a [routerLink]="round.link">
                <span>{{round.headline}}</span><mat-icon mat-list-icon>arrow_right</mat-icon>
              </a>
            </div>
          </mat-list>
        </ng-container>
        <ng-container *ngIf="round; else main">
          <mat-list>
            <div mat-list-item>
              <a [routerLink]="backlink">
                <span>Zurück zum Stand</span><mat-icon mat-list-icon>arrow_right</mat-icon>
              </a>
            </div>
          </mat-list>
        </ng-container>
        <ng-template #main>
          <mat-list>
            <div mat-list-item>
              <a routerLink="/tournaments">
                <span>Zurück zur Übersicht</span><mat-icon mat-list-icon>arrow_right</mat-icon>
              </a>
            </div>
          </mat-list>
        </ng-template>
      </mat-card-content>
    </mat-card>
</ng-container>
