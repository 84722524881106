import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NewsPageComponent} from './pages/news-list-page/news-page.component';
import {NewsPageModule} from './pages/news-list-page/news-page.module';
import {NewsDetailComponent} from './pages/news-detail-page/news-detail.component';
import {NewsDetailModule} from './pages/news-detail-page/news-detail.module';
import {HomePageModule} from './pages/home-page/home-page.module';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {ContentPageModule} from './pages/content-page/content-page.module';
import {ContentPageComponent} from './pages/content-page/content-page.component';
import {CalendarPageComponent} from './pages/calendar-page/calendar-page.component';
import {CalendarPageModule} from './pages/calendar-page/calendar-page.module';
import {TournamentPageComponent} from './pages/tournament-page/tournament-page.component';
import {TournamentPageModule} from './pages/tournament-page/tournament-page.module';
import {TournamentsPageComponent} from './pages/tournaments-page/tournaments-page.component';
import {TournamentsPageModule} from './pages/tournaments-page/tournaments-page.module';
import {chessWorldChannel, newsChannel} from './data/news';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomePageComponent
  },
  {
    path: 'content/:id',
    component: ContentPageComponent,
  },
  {
    path: 'news',
    component: NewsPageComponent,
    data: {
      channel: newsChannel
    }
  },
  {
    path: 'news/:id',
    component: NewsDetailComponent,
    data: {
      channel: newsChannel
    }
  },
  {
    path: 'chessworld',
    component: NewsPageComponent,
    data: {
      channel: chessWorldChannel
    }
  },
  {
    path: 'chessworld/:id',
    component: NewsDetailComponent,
    data: {
      channel: chessWorldChannel
    }
  },
  {
    path: 'calendar',
    component: CalendarPageComponent,
  },
  {
    path: 'tournaments',
    component: TournamentsPageComponent,
  },
  {
    path: 'tournament/:year/:type',
    component: TournamentPageComponent,
  },
  {
    path: 'tournament/:year/:type/:round',
    component: TournamentPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NewsPageModule,
    NewsDetailModule,
    HomePageModule,
    ContentPageModule,
    CalendarPageModule,
    TournamentPageModule,
    TournamentsPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
