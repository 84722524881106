import {Component, ViewEncapsulation} from '@angular/core';
import {chessWorldChannel, News, newsChannel} from '../../data/news';
import {CalendarItem} from '../../data/calendar';
import {ResourcesService} from '../../service/resources';
import {map} from 'rxjs/operators';
import {pages} from '../../data/content';
import {CalendarPageComponent} from '../calendar-page/calendar-page.component';
import {DateService} from '../../service/date/date.service';

@Component({
  selector: 'news-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HomePageComponent {
  constructor(
    private resourcesService: ResourcesService,
    private dateService: DateService,
  ) {
  }

  pages = pages;

  internalChannel = newsChannel;
  worldChessChannel = chessWorldChannel;

  nextCalendarItem$ = this.resourcesService.getResourceAsJSON('calendar').pipe(
    map( cal => cal as CalendarItem[]),
    map(calendar => this.dateService.sortItemsByDate(calendar) as CalendarItem[]),
    map(calendar =>  (calendar  as CalendarItem[]).filter(it => !it.archive)[0])
  );

}
