import {Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {Page} from '../../data/content';
import {ResourcesService} from '../../service/resources';

@Component({
  selector: 'news-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentPageComponent {
  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService
  ) {}
  @HostBinding('class') class = 'content-page';

  pages$: Observable<Page[]> = this.resourcesService.getResourceAsJSON('pages') as Observable<Page[]>;

  page$: Observable<Page> = combineLatest([this.pages$, this.route.params]).pipe(
    filter( ([pages, params]) => !!params.id),
    map( ([pages, params]) => pages.find(p => p.id === params.id)),
    filter( n => !!n)
  );

}
