import {NgModule} from '@angular/core';
import {HtmlParagraphComponent} from './html-paragraph.component';
import {CommonModule} from '@angular/common';
import {ResourcesModule} from '../../service/resources';

@NgModule({
  declarations: [
    HtmlParagraphComponent
  ],
    imports: [
        CommonModule,
        ResourcesModule.forRoot(),
    ],
  exports: [
    HtmlParagraphComponent
  ]
})
export class HtmlParagraphModule { }
