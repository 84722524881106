import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TournamentData, TournamentTable} from '../../data/tournaments';

@Component({
  selector: 'tournament-table',
  templateUrl: './tournament-table.component.html',
  styleUrls: ['./tournament-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TournamentTableComponent implements OnInit {
  @HostBinding('class') class = 'tournamenttable';
  @Input() year: string;
  @Input() tournamentType: string;
  @Input() round: string;
  @Input() tableType: string;
  @Input() tournamentTable: TournamentTable;
  @Input() tournamentData: TournamentData;
  resource: string;
  isDynamic: boolean;

  ngOnInit(): void {
    this.isDynamic = this.tournamentTable.isDynamic || (this.round && !!this.tournamentData);
    this.resource = `tournaments/${this.year}/${this.tournamentType}${this.round ? '/round' + this.round : ''}/${this.tournamentTable.resource}.html`;
  }
}
