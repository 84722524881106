import {ModuleWithProviders, NgModule} from '@angular/core';
import {ResourcesService} from './resources.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class ResourcesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ResourcesModule,
      providers: [
        ResourcesService,
      ],
    };
  }
}
