<ng-container *ngIf="current$ | async as current">
  <mat-card *ngIf="current">
    <mat-card-title>Aktuelle Turniere</mat-card-title>
    <mat-card-content>
      <year-tournaments [tournaments]="current"></year-tournaments>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-container *ngIf="{
  finished: finished$ | async,
  years: years$ | async
} as data">
  <ng-container *ngIf="data.finished">
    <mat-card>
      <mat-card-title>Beendete Turniere</mat-card-title>
      <mat-card-content>
        <ng-container *ngFor="let year of data.years">
          <year-tournaments [tournaments]="this.data.finished[year]"></year-tournaments>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>
<!--

<ng-container *ngIf="finished$ | async as finished">
  <mat-card *ngIf="finished.length">
    <mat-card-title>Beendete Turniere</mat-card-title>
    <mat-card-content>
      <mat-list>
        <div mat-list-item *ngFor="let tournament of finished">
          <a [routerLink]="tournament.link">
            <span>{{tournament.name}} {{tournament.yearDisplay}}</span><mat-icon mat-list-icon>arrow_right</mat-icon>
          </a>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ng-container>
-->
