import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResourcesModule} from '../../service/resources';
import {QuicktableModule} from '../quicktable/quicktable.module';
import {ResultTableComponent} from './result-table.component';

@NgModule({
  declarations: [
    ResultTableComponent
  ],
  imports: [
    CommonModule,
    ResourcesModule.forRoot(),
    QuicktableModule
  ],
  exports: [
    ResultTableComponent
  ]
})
export class ResultTableModule { }
