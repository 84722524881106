import {NgModule} from '@angular/core';
import {HomePageComponent} from './home-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {ShortNewsModule} from '../../components/short-news/short-news.module';

@NgModule({
  declarations: [
    HomePageComponent
  ],
    imports: [
        MatListModule,
        CommonModule,
        NewsModule,
        MatCardModule,
        RouterModule,
        ShortNewsModule
    ],
  exports: [
    HomePageComponent
  ]
})
export class HomePageModule { }
