<ng-container *ngIf="news">
  <div>
    <div class="left">{{news.date}}</div>
    <div class="right">
      <div class="title">{{news.headline}}</div>
      <p [innerHTML]="news.abstract"></p>
      <a routerLink="/{{channel.id}}/{{news.id}}" *ngIf="news.message">{{linkname}}</a>
      <a [routerLink]="news.link" *ngIf="news.link">{{linkname}}</a>
      <a [href]="news.externalLink" *ngIf="news.externalLink" target="_blank">{{linkname}}</a>
    </div>
  </div>
</ng-container>
