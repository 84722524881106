import {NgModule} from '@angular/core';
import {ContentPageComponent} from './content-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {HtmlTableModule} from '../../components/html-table/html-table.module';
import {QuicktableModule} from '../../components/quicktable/quicktable.module';
import {HtmlParagraphModule} from '../../components/html-paragraph/html-paragraph.module';
import {FeatureTableModule} from '../../components/feature-table/feature-table.module';

@NgModule({
  declarations: [
    ContentPageComponent
  ],
    imports: [
        MatListModule,
        CommonModule,
        NewsModule,
        MatCardModule,
        HtmlTableModule,
        HtmlParagraphModule,
        QuicktableModule,
        FeatureTableModule
    ],
  exports: [
    ContentPageComponent
  ]
})
export class ContentPageModule { }
