import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Channel, News} from '../../data/news';
import {map} from 'rxjs/operators';
import {ResourcesService} from '../../service/resources';
import {DateService} from '../../service/date/date.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'short-news',
  templateUrl: './short-news.component.html',
  styleUrls: ['./short-news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShortNewsComponent implements OnInit {
  @HostBinding('class') class = 'short-news';
  @Input() channel: Channel;

  constructor(
    private resourcesService: ResourcesService,
    private dateService: DateService
  ) {
  }
  currentNews$: Observable<News[]> = null;

  ngOnInit(): void {
    this.currentNews$ = this.resourcesService.getResourceAsJSON(this.channel.id).pipe(
      map(ns =>  (this.dateService.sortItemsByDate(ns as News[]) as News[]).filter(n => n.showhome).reverse())
    );
  }
}
