import {NgModule} from '@angular/core';
import {NewsDetailComponent} from './news-detail.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    NewsDetailComponent
  ],
    imports: [
        MatListModule,
        CommonModule,
        NewsModule,
        MatCardModule,
        RouterModule
    ],
  exports: [
    NewsDetailComponent
  ]
})
export class NewsDetailModule { }
