import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {GroupedTounaments, Tournament, tournamentTypes, YearTounaments} from '../../data/tournaments';
import {ResourcesService} from '../../service/resources';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'tournaments-page',
  templateUrl: './tournaments-page.component.html',
  styleUrls: ['./tournaments-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TournamentsPageComponent {
  constructor(
    private resourcesService: ResourcesService
  ) {
  }
  @HostBinding('class') class = 'tournaments';

  tournaments$: Observable<Tournament[]> = this.resourcesService.getResourceAsJSON('tournaments') as Observable<Tournament[]>;
  tournamentItems$ = this.tournaments$.pipe(
    map( tr => tr.map( tn => ({
    ...tn,
    name: tournamentTypes.find( tt => tt.id === tn.typeId)?.name,
    link: `/tournament/${tn.yearResource}/${tn.typeId}`
  }))));

  current$ = this.tournamentItems$.pipe(
    map( tr => tr.filter(tn => tn.finished === false)),
    map( tr => this.groupCurrentTournaments(tr))
  );
  finished$ = this.tournamentItems$.pipe(
    map( tr => tr.filter(tn => tn.finished === true)),
    map( tr => this.groupTournaments(tr))
  );
  years$ = this.finished$.pipe(
    map( yearsObj => Object.keys(yearsObj).sort().reverse())
  );

  groupTournaments(tournaments: Tournament[]): YearTounaments  {
    const ret: YearTounaments = {};
    tournaments.forEach( tourn => {
      const year = ret[tourn.yearResource] ? ret[tourn.yearResource] : {};
      year[tourn.typeId] = tourn;
      ret[tourn.yearResource] = year;
    });
    return ret;
  }

  groupCurrentTournaments(tournaments: Tournament[]): GroupedTounaments  {
    const ret: GroupedTounaments = {};
    tournaments.forEach( tourn => {
      ret[tourn.typeId] = tourn;
    });
    return ret;
  }

}
