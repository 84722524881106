import {NgModule} from '@angular/core';
import {TournamentPageComponent} from './tournament-page.component';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import {NewsModule} from '../../components/news/news.module';
import {MatCardModule} from '@angular/material/card';
import {ResourcesModule} from '../../service/resources';
import {TournamentTableModule} from '../../components/tournament-table/tournament-table.module';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    TournamentPageComponent
  ],
  imports: [
    MatListModule,
    CommonModule,
    NewsModule,
    MatCardModule,
    TournamentTableModule,
    RouterModule,
    MatIconModule
  ],
  exports: [
    TournamentPageComponent
  ]
})
export class TournamentPageModule { }
