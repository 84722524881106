import {Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import {News} from '../../data/news';
import {ResourcesService} from '../../service/resources';
import {DateService} from '../../service/date/date.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NewsPageComponent {
  constructor(
    private resourcesService: ResourcesService,
    private dateService: DateService,
    private route: ActivatedRoute
  ) {
  }

  @HostBinding('class') class = 'news-page';

  channel$ = this.route.data.pipe(
    map(data => data.channel),
  );

  currentNews$ = this.channel$.pipe(
    switchMap( data => this.resourcesService.getResourceAsJSON(data.id)),
    map(ns =>  this.dateService.sortItemsByDate(ns as News[]).reverse())
  );

  title$ = this.channel$.pipe(
    map(channel => channel?.title),
  );


}
