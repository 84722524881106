import {Injectable} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {distinctUntilChanged, filter, flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export enum BP  {
  GtXs = 'gt-xs',
  GtSm = 'gt-sm',
  GtMd = 'gt-md',
  GtM = 'gt-m'
}

@Injectable()
export class ResponsiveService {
  constructor(private mediaObserver: MediaObserver) {}

  private breakpoints$ = this.mediaObserver.asObservable().pipe(
    flatMap(mediaChange => mediaChange),
    filter(mediaChange => !!mediaChange.mqAlias),
  );

  public isBreakpoint$(breakpoint: BP): Observable<boolean> {
    return this.breakpoints$.pipe(
      map(_ => this.mediaObserver.isActive(breakpoint)),
      distinctUntilChanged(),
    );
  }
}
