export interface Page {
  id: string;
  headline: string;
  abstract?: string;
  sections?: Section[];
}

export interface Section {
  headline?: string;
  paragraph?: string;
  paragraphResource?: string;
  htmlTableResource?: string;
  featureTableResource?: string;
  quicktable?: string;
}

export const pages: Page[] = [
  {
    id: 'impressum',
    headline: 'Impressum',
    sections: [{
      headline: 'Schachclub Rathenow e.V.',
      paragraph:
        'Vorsitzender:<br><p>' +
        'Jörg Rahn<br>' +
        'Lerchenweg 2<br>' +
        '14712 Rathenow</p>'
    }]
  },
  {
    id: 'satzung',
    headline: 'Satzung',
    sections: [{
      paragraphResource: 'satzung'
    }]
  },
  {
    id: 'members',
    headline: 'Mitglieder',
    sections: [{
      htmlTableResource: 'members/members.html'
    }]
  },
  {
    id: 'training',
    headline: 'Training',
    sections: [
      {
      headline: 'Nachwuchs',
      quicktable:
        'Tag|Uhrzeit#Dienstag|17:00 Uhr - 18:30 Uhr#Freitag|17:00 Uhr - 18:30 Uhr'
      },
      {
        headline: 'Erwachsene',
        quicktable:
          'Tag|Uhrzeit#Freitag|19:00 Uhr'
      },
      {
        headline: 'Spiellokal',
        paragraph:
          'Grundschule "Geschwister Scholl"<br />' +
          'Geschwister-Scholl-Straße 7a<br />' +
          '14712 Rathenow'
      },
    ]
  },
  {
    id: 'charge',
    headline: 'Beitrag',
    sections: [
      {
        headline: 'Vollzahler',
        quicktable:
          'Zahlweise|Betrag#jährlich|102,00 €#halbjährlich|51,00 €#vierteljährlich|25,50 €'
      },
      {
        headline: 'Teilzahler',
        quicktable:
          'Zahlweise|Betrag#jährlich|66,00 €#halbjährlich|33,00 €#vierteljährlich|16,50 €'
      },
      {
        headline: 'Schüler',
        quicktable:
          'Zahlweise|Betrag#jährlich|50,40 €#halbjährlich|25,20 €#vierteljährlich|12,60 €'
      },
      {
        headline: 'Bankverbindung',
        quicktable:
          'Kontodaten|#Kontonummer|3861011467#Bankleitzahl|16050000#IBAN|DE42160500003861011467#BIC/Swift|WELADED1PMB#Institut|MBS Potsdam'
      },
    ]
  },


];

